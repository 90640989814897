import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from "../plugins/axios";

import offersModule from "./modules/offers";
import { themes as updatedThemes } from "./themes";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // Register your modules
    offersModule,
  },
  state: {
    text: "",
    color: "",
    timeout: "",
    loading: false,
    token: "",
    toggleLayout: true,
    sliderGroupTotalTime: 4000,
    themes: [],
    updatedThemes,
    fetchedThemes: [],
    profile: { coverImage: {} },
    planSelected: {},
    groupItems: [],
    showLogoLayer: false,
    ActiveScreenWidth: document.body.offsetWidth,
    accountStatus: "active",
    proTipDialog: {
      show: false,
      title: "",
      description: "",
    },
    fitTextLoader: false,
    fitTextCache: {
      "product-id": {
        "theme-id-1": {
          banner: 34,
          title: 25,
          description: 16,
        },
        "theme-id-2": {
          banner: 34,
          title: 25,
          description: 16,
        },
      },
    },
    inactiveThemes: [],
    sliderOperationsToken: Math.random(),
  },
  getters: {
    ActiveScreenWidth(state) {
      return state.ActiveScreenWidth;
    },
    showLogoLayer(state) {
      return state.showLogoLayer;
    },
    loading(state) {
      return state.loading;
    },
    fitTextLoader(state) {
      return state.fitTextLoader;
    },
    getLayoutPage(state) {
      return state.layoutPage;
    },
    token() {
      return localStorage.getItem("token");
    },
    getSliderGroupTotalTime(state) {
      return state.sliderGroupTotalTime;
    },
    themes(state) {
      return state.themes;
    },
    fetchedThemes(state) {
      return state.fetchedThemes;
    },
    toggleLayout(state) {
      return state.toggleLayout;
    },
    profile(state) {
      return state.profile;
    },
    role() {
      return localStorage.getItem("role");
    },
    groupItems: (state) => state.groupItems,
    updatedThemes(state) {
      return state.updatedThemes;
    },
    accountStatus(state) {
      return state.accountStatus;
    },
    proTipDialog(state) {
      return state.proTipDialog;
    },
    fitTextCache(state) {
      return state.fitTextCache;
    },
    getSliderOperationsToken(state) {
      return state.sliderOperationsToken;
    },
  },
  mutations: {
    SET_ALL_THEMES(state, payload) {
      state.themes = payload;
    },
    showLogoLayer(state, payload) {
      state.showLogoLayer = payload;
    },
    SET_PRODUCT_NUMBER(state, { productsAllowed, productsUsed }) {
      state.profile.planDetails.productsAllowed = productsAllowed || 0;
      state.profile.planDetails.productsUsed = productsUsed || 0;
    },
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout || 2000;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_FIT_TEXT_LOADER(state, payload) {
      state.fitTextLoader = payload;
    },
    SET_USER(state, payload) {
      //console.log(payload);
      localStorage.setItem("token", payload.token);
      if (payload.role == "user") {
        localStorage.setItem("role", "user");
        localStorage.setItem("email", payload.email);
        localStorage.setItem("name", payload.name);
      } else {
        localStorage.setItem("role", "admin");
        localStorage.setItem("email", payload.email);
        localStorage.setItem("name", payload.name);
      }
      state.token = payload.token;
    },
    REMOVE_USER(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("plan");
      state.token = "";
      router.go();
    },
    TOGGLE_LAYOUT(state, payload) {
      state.toggleLayout = payload;
    },
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
    SET_PLAN_SELECTED(state, payload) {
      state.planSelected = payload;
    },
    SET_GROUP_ITEMS(state, payload) {
      state.groupItems = payload;
    },
    SET_FETCHED_THEMES(state, payload) {
      state.fetchedThemes = payload;
    },
    SET_ACCOUNT_STATUS(state, payload) {
      state.accountStatus = payload;
    },
    SET_PRO_TIP_DIALOG(state, payload) {
      state.proTipDialog = payload;
    },
    SET_FIT_TEXT_CACHE(state, payload) {
      state.fitTextCache = payload;
    },
    SET_SLIDER_OPERATIONS_TOKEN(state, payload) {
      state.sliderOperationsToken = payload;
    },
  },
  actions: {
    setProductNumber({ commit }, payload) {
      commit("SET_PRODUCT_NUMBER", payload);
    },
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    toggleFitTextLoader({ commit }, payload) {
      console.log("SET_FIT_TEXT_LOADER", payload, new Date());
      commit("SET_FIT_TEXT_LOADER", payload);
    },
    setUser({ commit, dispatch }, payload) {
      let payloadClone = { ...payload, ...payload.user };
      commit("SET_USER", payloadClone);
      dispatch("setProfile");
    },
    logout({ commit }) {
      commit("REMOVE_USER");
    },
    toggleLayout({ commit }, payload) {
      commit("TOGGLE_LAYOUT", payload);
    },
    async setProfile({ state, commit, dispatch }, payload) {
      if (!payload) {
        if (state.token || localStorage.getItem("token")) {
          const role = state.role ?? localStorage.getItem("role");
          let urlApi =
            role === "admin"
              ? process.env.VUE_APP_ADMIN_PROFILE
              : process.env.VUE_APP_PROFILE;

          axios
            .get(urlApi)
            .then(async (res) => {
              // in the role === user case
              if (role === "user" && res.data[role].planDetails.status) {
                commit("SET_ACCOUNT_STATUS", res.data[role].planDetails.status);
              }

              await commit("SET_PROFILE", res.data[role || "user"] || {});
            })
            .catch(async (err) => {
              await commit("SET_PROFILE", {});
              dispatch("showSnack", {
                text: err.message || err.error || "Something went wrong",
                color: "error",
              });
            });
        }

        return;
      }

      commit("SET_PROFILE", payload);
    },
    setPlanSelected({ commit }, payload) {
      commit("SET_PLAN_SELECTED", payload);
    },
    setFetchedThemes({ state, commit }, payload) {
      axios
        .get(process.env.VUE_APP_THEMES)
        .then((res) => {
          commit(
            "SET_FETCHED_THEMES",
            res.data.filter(
              (theme) => !state.inactiveThemes.includes(theme.numericId)
            )
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setFitTextCache({ commit }, payload) {
      commit("SET_FIT_TEXT_CACHE", payload);
    },
    cleanFitTextCache({ state, commit }, payload) {
      if (!payload) {
        // clean all the cache
        commit("SET_FIT_TEXT_CACHE", {});
        return;
      }

      // only delete this product from cache
      let cache = state.fitTextCache;
      delete cache[payload];

      commit("SET_FIT_TEXT_CACHE", cache);
    },
    setProTipDialog(
      { commit },
      { show = false, title = "", description = "" }
    ) {
      commit("SET_PRO_TIP_DIALOG", { show, title, description });
    },
    setSliderOperationsToken({ state, commit }, payload) {
      console.log(payload, state.sliderOperationsToken);
      commit("SET_SLIDER_OPERATIONS_TOKEN", payload);
    },
    alterFirstLogin({ dispatch }) {
      axios
        .post(process.env.VUE_APP_ALTER_FIRST_LOGIN)
        .then((res) => {
          console.log(res);
          dispatch("setProfile");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    alterFirstTimePremium({ dispatch }) {
      axios
        .post(process.env.VUE_APP_ALTER_FIRST_TIME_PREMIUM)
        .then((res) => {
          console.log(res);
          dispatch("setProfile");
        })
        .catch((err) => {
          console.log(err, process.env.VUE_APP_ALTER_FIRST_TIME_PREMIUM);
        });
    },
  },
});
