import Vue from "vue";
import { mapGetters } from "vuex";
import SliderMethods from "./SliderMethods";

export const globalMixin = {
  data() {
    return {
      fitTextQueue: [],
      currentLoopInstance: Math.random(),
      // timeouts: [],
    };
  },
  computed: {
    ...mapGetters([
      "profile",
      "role",
      "fetchedThemes",
      "updatedThemes",
      "accountStatus",
      "fitTextLoader",
    ]),
    currencySymbol() {
      return this.$store.state.profile?.currency?.symbol ?? "$$";
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.md
      );
    },
  },

  methods: {
    ...SliderMethods,
    dateToTimezone(date = new Date()) {
      return new Date(date).toLocaleString("en-US", {
        timeZone: this.profile.timeZone,
      });
    },
    moment_tz(date) {
      if (!date) {
        return this.$moment().tz(this.profile.timeZone);
      }

      return this.$moment(date).tz(this.profile.timeZone);
    },
    calcTime(activeIndex, arrayOfTime, numOfSlidesPerView) {
      // console.log(activeIndex, arrayOfTime, numOfSlidesPerView);
      let time = 0;
      let firstStep =
        activeIndex == 0 ? 0 : activeIndex + numOfSlidesPerView - 1;
      let times = arrayOfTime.slice(firstStep, firstStep + numOfSlidesPerView);
      // console.log(times);
      for (let i = 0; i < times.length; i++) {
        time += times[i];
      }
      // console.log(time * 1000);
      return time * 1000;
    },
    clearTimeouts() {
      this.timeouts.forEach((timeout) => {
        clearTimeout(timeout);
      });
    },
    // function to truncate string by (WORD NOT CHAR )to specific length (per length)
    truncateString(str, num) {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + "...";
    },
    hasSomeContent(product, checKBannerAlso) {
      if (checKBannerAlso) {
        return Boolean(
          product.item.banner?.text ||
            product.item.title ||
            product.item.description ||
            product.item.price ||
            product.item.oldPrice
        );
      } else {
        return Boolean(
          product.item.title ||
            product.item.description ||
            product.item.price ||
            product.item.oldPrice
        );
      }
    },
    isOverflown(element) {
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );
    },
    wrapString(str, perLengthWrap) {
      if (str.length <= perLengthWrap) return str;
      let arr = str.split(" ");
      let res = "";
      let temp = "";
      arr.forEach((element) => {
        if (temp.length + element.length > perLengthWrap && temp.length) {
          res += temp + "<br>";
          temp = "";
        }
        temp += element + " ";
      });
      res += temp;
      return res;
    },

    $structuredClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    async uploadLogo(imgDataUrl) {
      let file = this.dataURLtoFile(imgDataUrl, "image.png");
      let formData = new FormData();
      formData.append("logo", file);
      // formData.append("coverImage", file);

      let urlApi = process.env.VUE_APP_PROFILE;
      if (this.role == "admin") {
        urlApi = process.env.VUE_APP_ADMIN_PROFILE;
      }

      return this.$axios
        .patch(urlApi, formData)
        .then(async (res) => {
          this.$store.dispatch("showSnack", {
            text: "Edited Successfully",
            color: "success",
          });

          // console.log({ res });

          // update profile
          await this.$store.dispatch("setProfile");
          return Promise.resolve(res);
        })
        .catch((err) => {
          this.$store.dispatch("showSnack", {
            text: err.message || err.error || "Something went wrong",
            color: "error",
          });
          return Promise.reject(err);
        });
    },
    dataURLtoFile(dataUrl, filename) {
      var arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    extractedYoutubeEmbedUrl(url) {
      let regex =
        /(?:https?:\/\/)?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/shorts\/)([^\s&?]+)/;
      let match = url.match(regex);
      let embedUrl = `https://www.youtube.com/embed/${match[1]}?playlist=${match[1]}&loop=1&autoplay=1&amp;controls=0&mute=1&rel=0&showinfo=0&iv_load_policy=3`;
      console.log({ embedUrl });
      return embedUrl;
    },
    extractYoutubeThumbnailUrl(url) {
      const regExp =
        /(?:https?:\/\/)?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/shorts\/)([^\s&?]+)/;
      const match = url.match(regExp);
      // youtube thumbnail url
      console.log("match", match);
      return `http://img.youtube.com/vi/${match[1]}/0.jpg`;
    },
    filterProducts(products) {
      return products.filter((product) => {
        return product && !product?.item?.deleted && product?.item?.active;
      });
    },
    isOutOfDateRange(startDate, endDate) {
      const today = new Date().toISOString();
      const start = new Date(startDate).toISOString();
      const end = new Date(endDate).toISOString();
      return today < start || today > end;
    },
    // fitText(element, props, log = false) {
    //   /*
    //     should give the maximum font size that makes the element fit the given percentage of the parent's height.
    //     should consider the space that the currency element takes up in case of price elements.
    //     should not exceed the width of the parent element.
    //   */
    //   return new Promise((resolve) => {
    //     log && console.log("started");
    //     function calcHeightWithoutPadding(el) {
    //       // calculate the height of the element without padding
    //       let style = window.getComputedStyle(el, null);
    //       let height = el.offsetHeight;
    //       height -=
    //         parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
    //       return height;
    //     }

    //     function isOverflown(element) {
    //       // checks if the element is overflown
    //       return (
    //         element.clientHeight > maxHeight ||
    //         element.scrollWidth > element.clientWidth
    //       );
    //     }

    //     let maxHeight;
    //     if (element.nextElementSibling) {
    //       maxHeight =
    //         (calcHeightWithoutPadding(element.parentElement) / 100) *
    //         props.maxHeightByPercent;

    //       log && console.log("nextElementSibling", maxHeight);
    //     } else {
    //       if (props.checkHasSibling) {
    //         // console.log("from inside sibklsasd");
    //         // console.log(
    //         //   calcHeightWithoutPadding(element.parentElement),
    //         //   element.parentElement
    //         // );
    //         maxHeight = calcHeightWithoutPadding(element.parentElement);

    //         log && console.log("checkHasSibling", maxHeight);
    //       } else {
    //         maxHeight =
    //           (calcHeightWithoutPadding(element.parentElement) / 100) *
    //           props.maxHeightByPercent;

    //         log && console.log("no siblings", maxHeight);
    //       }
    //     }
    //     // console.log("maxHeight", maxHeight);
    //     let fontSize = (props.maxFont + props.minFont) / 2;
    //     let low = props.minFont;
    //     let md;
    //     let high = props.maxFont;
    //     let step = 1;
    //     let cacheFont = fontSize;
    //     let isMaxPossibleFontSize = false;

    //     if (element.innerText.trim().length) {
    //       // to low, when the element did not reach the maximum height
    //       // to high, when the element exceeded the maximum height or has overflow
    //       while (!isMaxPossibleFontSize) {
    //         md = (high + low) / 2;
    //         fontSize = md;
    //         log && console.log("fontSize in loop: ", fontSize);
    //         log && console.log("is overflown: ", isOverflown(element));
    //         log &&
    //           console.log(
    //             "heights: ",
    //             calcHeightWithoutPadding(element),
    //             maxHeight
    //           );

    //         element.style.fontSize = md + "px";

    //         setTimeout(() => {
    //           // is it too low?
    //           if (calcHeightWithoutPadding(element) <= maxHeight - 10) {
    //             low = md + step;
    //           } else if (
    //             // is it too high?
    //             calcHeightWithoutPadding(element) > maxHeight ||
    //             isOverflown(element)
    //           ) {
    //             high = md - step;
    //           }

    //           if (low > high) {
    //             log && console.log("break", low, high);
    //             isMaxPossibleFontSize = true;
    //           } else {
    //             // store the last font size
    //             cacheFont = fontSize;
    //           }
    //         }, 0);

    //         // fit font based on width and height and check if the element has overflow
    //         // if (
    //         //   calcHeightWithoutPadding(element) >= maxHeight ||
    //         //   isOverflown(element)
    //         // ) {
    //         //   high = md - step;
    //         // } else {
    //         //   low = md + step;
    //         // }
    //       }

    //       element.style.fontSize = fontSize + "px";
    //       log && console.log("font size after loop: ", fontSize);
    //       log && console.log("element: ", element);

    //       let currencyFontSize = fontSize / 2;

    //       if (cacheFont !== fontSize) {
    //         element.style.fontSize = cacheFont + "px";
    //         currencyFontSize = cacheFont / 2;
    //       }
    //       // if (
    //       //   calcHeightWithoutPadding(element) >= maxHeight ||
    //       //   isOverflown(element)
    //       // ) {
    //       //   element.style.fontSize = props.minFont + "px";
    //       //   currencyFontSize = props.minFont / 2;
    //       // }
    //       element.style.lineHeight = "1.2";
    //       // currency should be 1/4 of the font size of the price
    //       if (props.quarterChild) {
    //         let currency = element.querySelector(".currency");
    //         currency.style.fontSize = currencyFontSize + "px";
    //       }
    //     }

    //     resolve();
    //   });
    // },
  },
};

Vue.mixin(globalMixin);
Vue.directive("textFit", {
  bind(element, binding, vnode) {
    function calcHeightWithoutPadding(el) {
      // calculate the height of the element without padding
      let style = window.getComputedStyle(el, null);
      let height = el.offsetHeight;
      height -= parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
      return height;
    }
    function isOverflown(element) {
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );
    }

    // setTime because bind hook is called before the element is rendered so we need to wait until the element is rendered
    setTimeout(() => {
      let maxHeight;
      if (element.nextElementSibling) {
        maxHeight =
          (calcHeightWithoutPadding(element.parentElement) / 100) *
          binding.value.maxHeightByPercent;

        if (element.id === "banner-text")
          console.log("nextElementSibling", maxHeight);
      } else {
        if (binding.value.checkHasSibling) {
          // console.log("from inside sibklsasd");
          // console.log(
          //   calcHeightWithoutPadding(element.parentElement),
          //   element.parentElement
          // );
          maxHeight = calcHeightWithoutPadding(element.parentElement);

          if (element.id === "banner-text")
            console.log("checkHasSibling", maxHeight);
        } else {
          maxHeight =
            (calcHeightWithoutPadding(element.parentElement) / 100) *
            binding.value.maxHeightByPercent;

          if (element.id === "banner-text")
            console.log("no siblings", maxHeight);
        }
      }
      // console.log("maxHeight", maxHeight);
      let fontSize = maxHeight;
      let low = binding.value.minFont;
      let md;
      let high = Math.floor(maxHeight);
      let step = 1;
      let cacheFont = fontSize;
      if (element.innerText.trim().length) {
        if (element.id === "banner-text")
          console.log("there's text", low, high);
        element.style.fontSize = fontSize + "px";
        while (low <= high) {
          md = (high + low) / 2;
          fontSize = md;
          element.style.fontSize = fontSize + "px";
          if (element.id === "banner-text")
            console.log("fontSize in loop: ", fontSize);

          // fit font based on width and height and check if the element has overflow
          if (
            calcHeightWithoutPadding(element) >= maxHeight ||
            isOverflown(element)
          ) {
            high = md - step;
          } else {
            low = md + step;
            cacheFont = fontSize;
          }
        }

        let currencyFontSize = fontSize / 2;

        if (cacheFont !== fontSize) {
          element.style.fontSize = cacheFont + "px";
          currencyFontSize = cacheFont / 2;
        }
        if (
          calcHeightWithoutPadding(element) >= maxHeight ||
          isOverflown(element)
        ) {
          element.style.fontSize = binding.value.minFont + "px";
          currencyFontSize = binding.value.minFont / 2;
        }
        element.style.lineHeight = "1.2";
        // currency should be 1/4 of the font size of the price
        if (binding.value.quarterChild) {
          let currency = element.querySelector(".currency");
          currency.style.fontSize = currencyFontSize + "px";
        }
      }
    }, 10);
  },
});
