<template>
  <div class="delete-dialog">
    <div class="px-10 text-center">
      <h1 class="mt-10 mb-2 d-block font-20 black--text font-500">
        {{ warningText }}
      </h1>

      <p v-if="description">
        {{ description }}
      </p>

      <div class="mb-10">
        <v-btn
          class="c-btn mx-1 mb-1 mb-sm-0"
          color="grey"
          height="40"
          @click="$emit('close')"
          ><span class="white--text font-300 font-20 d-inline-block px-7"
            >No</span
          ></v-btn
        >
        <v-btn
          class="c-btn mx-1 mb-1 mb-sm-0"
          color="error"
          height="40"
          :loading="loading"
          :disabled="loading"
          @click="deleteType === 'archive' ? archiveHandler() : deleteHandler()"
          ><span class="white--text font-300 font-20 d-inline-block px-7"
            >Yes</span
          ></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    urlApi: {
      type: String,
      default: "",
    },
    itemID: {
      type: [String, Array],
      default: "",
    },
    deleteType: {
      type: String,
      default: "delete",
      required: false,
    },
    warningText: {
      type: String,
      default: "Are you sure?",
      required: false,
    },
    description: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async archiveHandler() {
      this.loading = true;
      let { data } = await this.$axios.put(
        `${this.urlApi}/${this.itemID}/status`,
        {
          status: "archived",
        }
      );

      if (data.success) {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: "Archived Successfully",
          color: "success",
        });

        this.deleteDialog = false;
        this.$emit("close");
        this.$emit("getData", data);
      } else {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
    async deleteHandler() {
      if (this.deleteType === "confirm") {
        this.$emit("confirm");
        return;
      }

      this.loading = true;
      let { data } = await this.$axios.delete(`${this.urlApi}/${this.itemID}`);
      if (data.success) {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: "Deleted Successfully",
          color: "success",
        });
        this.deleteDialog = false;
        this.$emit("close");
        this.$emit("getData", data);
      } else {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
