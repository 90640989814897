<template>
  <div
    class="account-suspended"
    v-if="accountStatus && accountStatus === 'canceled'"
  >
    Sorry, your account is canceled!
  </div>

  <div
    class="account-suspended"
    v-else-if="accountStatus && accountStatus === 'incomplete_expired'"
  >
    Sorry, your account is expired!
  </div>

  <div
    class="account-suspended"
    v-else-if="accountStatus && accountStatus === 'inactive'"
  >
    Sorry, your account is inactive!
    <div class="d-flex">
      <v-btn
        @click="redirectToPayment()"
        class="confirm-payment"
        color="danger"
        :loading="processing"
      >
        Please confirm the payment to continue
      </v-btn>

      <v-btn
        @click="logoutHandler()"
        class="logout text-danger"
        color="transparent"
        :loading="processing"
      >
        Logout
      </v-btn>
    </div>
  </div>

  <div
    class="account-suspended"
    v-else-if="accountStatus && accountStatus === 'incomplete'"
  >
    Sorry, your account is incomplete!
  </div>

  <div
    class="account-suspended"
    v-else-if="accountStatus && accountStatus === 'paused'"
  >
    Sorry, your account is currently closed!
    <v-btn
      @click="redirectToPayment()"
      class="confirm-payment"
      color="danger"
      :loading="processing"
    >
      Please confirm the payment to continue
    </v-btn>
  </div>

  <div
    class="account-suspended"
    v-else-if="accountStatus && accountStatus === 'past_due'"
  >
    Sorry, your account is currently closed!
    <v-btn
      @click="redirectToPayment()"
      class="confirm-payment"
      color="danger"
      :loading="processing"
    >
      Please confirm the payment to continue
    </v-btn>
  </div>

  <v-app v-else class="app">
    <snackbar></snackbar>
    <router-view />

    <v-dialog
      v-model="proTipDialog.show"
      content-class="white pa-8"
      max-width="530px"
    >
      <pro-notice
        @close="
          $store.dispatch('setProTipDialog', {
            show: false,
          })
        "
        :title="proTipDialog.title"
        :description="proTipDialog.description"
      />
    </v-dialog>
  </v-app>
</template>

<script>
import Snackbar from "@/components/global/Snackbar";
import ProNotice from "@/components/dialogs/ProNotice.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Snackbar,
    ProNotice,
  },
  data() {
    return {
      processing: false,
    };
  },
  computed: {
    ...mapGetters(["role", "accountStatus", "proTipDialog", "token"]),
  },
  methods: {
    logFullScreen: function () {
      if (document.fullscreenElement) {
        //console.log("Changed to fullscreen at " + this.currentDatetime);
      } else {
        this.$store.dispatch("toggleLayout", true);
      }
    },
    redirectToPayment() {
      this.processing = true;

      this.$axios
        .post(process.env.VUE_APP_SUBSCRIPTIONS, {
          plan: this.profile.plan._id,
          stripeId: this.profile.trial.planStripeId,
        })
        .then((res) => {
          window.open(res.data.sessionUrl, "_blank");
          this.processing = false;
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("showSnack", {
            text: "Something went wrong!",
            color: "error",
          });
          this.processing = false;
        });
    },
    logoutHandler() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },

    // async getProfile() {
    //   let urlApi = process.env.VUE_APP_PROFILE;
    //   let { data } = await this.$axios.get(urlApi);
    //   if (data.success) {
    //     let accountDetails = {
    //       ...data[this.role],
    //       ...(this.role == "user" ? { package: data.package } : {}),
    //     };
    //     this.$store.dispatch("setProfile", accountDetails);
    //   }
    // },
  },
  async mounted() {
    if (this.token) {
      this.$store.dispatch("setProfile");
      this.$store.dispatch("setFetchedThemes");
    }
    document.addEventListener("fullscreenchange", this.logFullScreen);
  },
  destroyed() {
    document.removeEventListener("fullscreenchange", this.logFullScreen);
  },
  watch: {
    profile: {
      deep: true,
      handler() {
        // fetch themes every time the profile changes
        this.$store.dispatch("setFetchedThemes");
      },
    },
  },
};
</script>

<style lang="scss">
.app {
  background: white !important;

  .content {
    margin-left: 260px;
    max-width: 1400px;
    height: 100vh;
    padding-inline: 50px;
  }
}

.account-suspended {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .v-btn.confirm-payment {
    background-color: $danger !important;
    color: white;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
  }

  .logout {
    margin-top: 10px;
    margin-left: 10px;
  }
}
</style>
