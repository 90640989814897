import Vue from "vue";
import axios from "axios";
import store from "../store";
import router from "../router";
// Vue.prototype.$baseURL = "195.179.193.133:3001";

// axios.defaults.baseURL = `http://195.179.193.133:3001/api`;
// axios.defaults.headers.common = {'x-auth-token': `${localStorage.getItem('token')}`}
// window.axios = axios;
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.url) return;
    store.dispatch("loading", true);
    config.headers["x-auth-token"] = localStorage.getItem("token");

    return config;
  },
  (error) => {
    //console.log("error", error);
    return Promise.reject(error);
  }
);

function handleErrorResponse(error) {
  if (error.response?.status === 401) {
    const currentRoute = router.currentRoute;

    console.log(currentRoute, currentRoute.meta.requiresAuth);

    if (!currentRoute.name.includes("Auth") || currentRoute.meta.requiresAuth) {
      store.dispatch("logout");
    }
  }

  return Promise.reject(error.response?.data);
}

axiosInstance.interceptors.response.use(
  function (response) {
    store.dispatch("loading", false);
    return response;
  },
  function (error) {
    store.dispatch("loading", false);
    return handleErrorResponse(error);
  }
);

Vue.prototype.$axios = axiosInstance;

export default axiosInstance;
