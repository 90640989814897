import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // check if   logged
  //console.log(to.matched, 33);
  let isLogin = localStorage.getItem("token");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // if not, redirect to login page.
    if (isLogin) {
      next();
    } else {
      next({
        path: "/signin",
      });
    }
  } else {
    if (isLogin && to.matched.some((record) => record.meta.auth)) {
      if (
        (localStorage.getItem("role") == "admin" && to.path == "/signin") ||
        (localStorage.getItem("role") == "user" && to.path == "/signin-admin")
      ) {
        next();
      } else if (localStorage.getItem("role") == "user") {
        next({
          path: "/",
        });
      } else {
        next({
          path: "/admin/plans",
        });
      }
    } else {
      next();
    }
  }
});

export default router;
