<template>
  <div class="base-input input-style base-select" :class="isWeight ? 'base-weight': ''">
    <v-select
      :items="items"
      outlined
      :placeholder="placeholder"
      dense
      height="44px"
      max-height="44px"
      :hide-details="hideDetails"
      :item-value="itemValue"
      :item-text="itemText"
      :value="value"
      :rules="rules"
      :multiple="multiple"
      @input="$emit('input', $event)"
    >
    </v-select>
    <span v-if="isWeight" class="budget font-15 purple--text d-inline-block"
      >KG</span
    >    
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "_id",
    },
    itemText: {
      type: String,
      default: "name",
    },
    isWeight: {
      type: Boolean,
      default: false,
    },    
    value: {
      validator: () => true,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || ""],
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.base-select {
  position: relative;

  .v-input__slot {
    border-radius: 10px;
    .v-input__append-inner{
      margin-top: 0px !important; 
    }
    fieldset {
      height: 44px !important;
      border: 1px solid rgba(0,0,0,.1) !important;
      box-shadow: 0px 2px 10px rgb(168 168 168 / 5%);
    }
  
    .v-text-field__slot {
      height: 44px !important;
    }
  }
  .error--text {
    .v-input__slot {
      fieldset {
        height: 44px !important;
        border: 1px solid #e44358 !important;
      }
    }
  }
}

</style>
