import axios from ".././plugins/axios";
const routes = [
  {
    path: "/signin-admin",
    name: "Auth_Signin_Admin",
    meta: { auth: true },
    component: () => import("../views/auth/signin-admin/Index.vue"),
  },
  {
    path: "/signin",
    name: "Auth_Signin",
    meta: { auth: true },
    component: () => import("../views/auth/signin/Index.vue"),
  },
  {
    path: "/verify",
    name: "Auth_Verify",
    meta: { auth: true },
    component: () => import("../views/auth/verify/Index.vue"),
  },
  {
    path: "/verifyemail",
    name: "Auth_VerifyEmail",
    meta: { auth: true },
    component: () => import("../views/auth/verify/Index.vue"),
  },
  {
    path: "/verifypassword",
    name: "Auth_VerifyPassword",
    meta: { auth: true },
    component: () => import("../views/auth/reset-password/Index.vue"),
  },
  {
    path: "/forget-password",
    name: "Auth_ForgetPassword",
    meta: { auth: true },
    component: () => import("../views/auth/forget-password/Index.vue"),
  },
  {
    path: "/signup",
    name: "Auth_Signup",
    meta: { auth: true },
    component: () => import("../views/auth/signup/Index.vue"),
  },
  {
    path: "/plans",
    name: "Plans",
    component: () => import("../views/plans/Index.vue"),
  },
  {
    meta: { auth: true },
    path: "/sliders",
    name: "sliders",
    component: () => import("@/components/themes/TwoSlides.vue"),
  },
  {
    path: "/",
    meta: { requiresAuth: true },
    component: () => import("../layout/MainLayout"),
    children: [
      {
        path: "/",
        name: "offers",
        component: () => import("../views/offers/Index.vue"),
      },
      {
        path: "/offers-page",
        name: "OffersDashboard",
        // meta: { requiresIsOffer: true },
        // async beforeEnter(to, from, next) {
        //   let { data } = await axios.get(process.env.VUE_APP_PROFILE);
        //   //console.log(data,474)
        //   if (data.success) {
        //     if (data.user.plan.isOfferPage) {
        //       next();
        //     } else {
        //       next({
        //         path: "/",
        //       });
        //     }
        //   }
        // },

        component: () => import("../views/offers/OffersDashboard.vue"),
      },
      {
        path: "/add-template",
        name: "add-template",
        component: () => import("../views/add-template/Index.vue"),
      },
      {
        path: "/screens",
        name: "Screens",
        component: () => import("../views/preview/Index.vue"),
      },
      {
        path: "/settings",
        name: "Settings",
        component: () => import("../views/settings/Index.vue"),
      },
      {
        path: "/my-plan",
        name: "My Plan",
        component: () => import("../views/upgrade/Index.vue"),
      },
      {
        path: "/success",
        name: "Success",
        component: () => import("../views/upgrade/components/Success.vue"),
      },
      {
        path: "/payment",
        name: "Payment",
        component: () => import("../views/payment/Index.vue"),
      },
      {
        path: "/admin/users",
        name: "Admin Users",
        component: () => import("../views/admin/users/Index.vue"),
      },
      {
        path: "/admin/packages",
        name: "Admin Packages",
        component: () => import("../views/admin/packages/Index.vue"),
      },
      {
        path: "/admin/plans",
        name: "Admin Plans",
        component: () => import("../views/admin/plans/Index.vue"),
      },
    ],
  },
  {
    path: "/welcome",
    name: "Welcome",
    meta: { requiresAuth: false },
    component: () => import("../views/welcome/Index.vue"),
  },
  {
    path: "/code",
    name: "CODE",
    component: () => import("../views/tv/Index.vue"),
  },
  // {
  //   path: "/test",
  //   name: "CODE",
  //   component: () => import("../views/test.vue"),
  // },
  {
    path: "/code/:code",
    name: "CODE",
    component: () => import("../views/tv/Index.vue"),
  },
  {
    path: "/qrcode",
    name: "Scan",
    component: () => import("../views/scan-preview/Index.vue"),
  },
  // {
  //   path: "/ofero-co/offers",
  //   name: "Offers Near Me",
  //   component: () => import("../views/offers/OffersNearMe.vue"),
  // },
  {
    path: "/offers/:id",
    name: "OffersPage",
    component: () =>
      import("../views/offers/public-offers-page/OffersPage.vue"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

export default routes;
