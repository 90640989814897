/* 
  slider methods lifecycle:
    1. put theme parts in a queue and process each one *synchronously*
    2. first, handle font size calculations
    3. second, handle animation classes

  A theme part: an object representing a part of the theme like (title, description, price, banner, etc) and it has these properties:
        className: the class given to html elements of this part
        animationElementClass: if provided, it represent the class given to the element that will have the animation classes
        props: [minFont, maxFont, maxHeightByPercent, quarterChild (optional, for price elements), checkHasSibling (optional, for price elements), animationName, animationDelay, relativeExist]
        log: boolean for optional console logs, for debugging

*/

export default {
  tokenMismatch(token) {
    let isMismatch = this.$store.state.sliderOperationsToken !== token;
    if (isMismatch) {
      console.log(
        "token mismatch",
        this.$store.state.sliderOperationsToken,
        token
      );
      return isMismatch;
    }
  },
  configureFontSizesAndAnimations(themeParts, products, themeId) {
    // generate new operations token
    let token = Math.random();
    this.$store.dispatch("setSliderOperationsToken", token);

    return new Promise((resolve, reject) => {
      this.$store.dispatch("toggleFitTextLoader", true);
      const injectThemePartsToQueue = () => {
        return new Promise((resolve) => {
          themeParts.forEach((themePart, i, arr) => {
            // if no relative, give 100% height
            let elements = document.querySelectorAll("." + themePart.className);
            elements.forEach((element, i) => {
              const productId = element.getAttribute("data-product-id");
              const product = products.find((p) => p.item._id === productId);
              if (themePart.relative && product) {
                let relativeExists = product.item[themePart.relative];
                element.dataset.relativeExists = relativeExists
                  ? "relativeExists"
                  : "";
              } else {
                // no defined relative, so leave it as it is
                element.dataset.relativeExists = "";
              }
            });

            // add theme part to the queue
            this.enqueueThemePart({ ...themePart, themeId });

            if (i === arr.length - 1) {
              resolve();
            }
          });
        });
      };

      injectThemePartsToQueue().then(() => {
        this.startProcessingQueue(token)
          .then(() => {
            this.$store.dispatch("toggleFitTextLoader", false);
            resolve();
          })
          .catch((e) => {
            console.log("e: ", e);
            reject();
          });
      });
    });
  },
  enqueueThemePart(themePart) {
    // add the theme part to the queue (with all its elements in case of a theme with more than one product on each slide)
    this.fitTextQueue = [...this.fitTextQueue, themePart];
  },
  startProcessingQueue(token) {
    // called after putting all the theme parts to the queue
    return new Promise((resolve, reject) => {
      let queue = this.fitTextQueue;

      function sanitizeFontSizes() {
        // sanitize all elements' font sizes so that no element disrupt other elements calculations
        return new Promise((resolve) => {
          queue.forEach(({ className }, i, array) => {
            let elements = document.querySelectorAll("." + className);

            elements.forEach((element) => {
              // remove any animation classes to avoid mis-calculations
              element.classList.remove(
                ...Array(...element.classList).filter((className) =>
                  className.includes("animate")
                )
              );

              element.style.lineHeight = "1.2";
              element.style.fontSize = "1px";
            });

            if (array.length === i + 1) {
              resolve();
            }
          });
        });
      }

      if (this.tokenMismatch(token)) {
        return reject();
      }

      sanitizeFontSizes().then(() => {
        // process font sizes first, then process animations
        this.processQueue(
          this.fitTextQueue,
          this.processThemePartFontSizes,
          token
        )
          .then(() => {
            this.handleAnimationClasses(this.fitTextQueue, token).then(() => {
              this.fitTextQueue = [];
              resolve();
            });
          })
          .catch(() => {
            console.log("reject processQueue");
            reject();
          });
      });
    });
  },
  processQueue(queue, handler, token) {
    if (this.tokenMismatch(token)) {
      return Promise.reject();
    }

    // takes a theme part and a handler to call on, either the font sizes or the animation classes handler
    const processNextItem = () => {
      if (queue.length === 0) {
        // Resolve when the queue is empty
        return Promise.resolve();
      }

      let [themePart, ...rest] = queue;
      queue = rest; // Update the queue

      return handler(themePart, token)
        .then(() => processNextItem())
        .catch((e) => {
          console.log("reject handler", e, handler);
          return Promise.reject();
        }); // Process the next item
    };

    return processNextItem(); // Start processing
  },
  processThemePartFontSizes({ className, props, log = false, themeId }, token) {
    if (this.tokenMismatch(token)) {
      return Promise.reject();
    }

    let elements = document.querySelectorAll("." + className);
    const fitTextPromises = Array.from(elements).map((element) => {
      const productId = element.getAttribute("data-product-id");
      return this.fitText(
        element,
        props,
        log,
        productId,
        themeId,
        className,
        token
      );
      // this.prepareAnimationClasses(element, props, animationElementClass);
    });

    return Promise.all(fitTextPromises);
  },
  handleAnimationClasses(queue) {
    // loop over all theme parts and handle their animation classes asynchronously
    queue.forEach(({ className, animationElementClass, props }) => {
      let elements = document.querySelectorAll("." + className);
      elements.forEach((element) => {
        this.prepareAnimationClasses(element, props, animationElementClass);
      });
    });

    return Promise.resolve();
  },
  prepareAnimationClasses(el, props, animationElementClass) {
    let animationName = props.animationName;
    let animationDelay = String(props.animationDelay).replace(".", "-");

    if (!animationName) {
      return Promise.resolve();
    }

    const animationDelayClass = animationDelay
      ? `animate__delay-${animationDelay}s`
      : "";

    const animationClass = this.animationEffects.find(
      (effect) => effect.name === animationName
    )?.class;

    let classes = [
      "animate__animated",
      animationClass,
      animationDelayClass,
      "opacity-0",
    ];

    let element = el;
    if (animationElementClass) {
      element = el.querySelector(`.${animationElementClass}`);
    }

    element.classList.add(
      ...classes.filter((c) => c !== "" && c !== undefined)
    );

    setTimeout(() => {
      element.classList.remove("opacity-0");
    }, Number(animationDelay) * 1000);

    return Promise.resolve();
  },
  isCacheEntryExists(productId, themeId, className) {
    let fitTextCache = this.$store.state.fitTextCache;
    return !!(
      fitTextCache[productId] &&
      fitTextCache[productId][themeId] &&
      fitTextCache[productId][themeId][className]
    );
  },
  fitText(element, props, log = false, productId, themeId, className, token) {
    return new Promise((resolve, reject) => {
      const vm = this;
      let fontSize;
      let maxHeightByPercent =
        element.dataset.relativeExists === "relativeExists"
          ? props.maxHeightByPercent
          : 100;
      const currencyElement = element.querySelector(".currency");
      const parentStyle = window.getComputedStyle(element.parentElement, null);
      const parentHeight =
        parseInt(window.getComputedStyle(element.parentElement).height) -
        (parseFloat(parentStyle.paddingTop) +
          parseFloat(parentStyle.paddingBottom));
      const maxHeight = maxHeightByPercent * (parentHeight / 100);

      // stop if there's a cache value
      if (this.isCacheEntryExists(productId, themeId, className)) {
        let cachedValue =
          this.$store.state.fitTextCache[productId][themeId][className];

        element.style.fontSize = cachedValue + "px";
        if (currencyElement)
          currencyElement.style.fontSize = cachedValue / 2 + "px";
        resolve();
        return;
      }

      log &&
        console.log("***************started***************", {
          maxHeight,
          maxHeightByPercent: maxHeightByPercent,
          parentHeight: element.parentElement.offsetHeight,
          parentPadding: [parentStyle.paddingTop, parentStyle.paddingBottom],
          currencyElement,
          parent: element.parentElement,
        });

      function cleanup() {
        element.style.fontSize = 1 + "px";
        if (currencyElement) currencyElement.style.fontSize = 1 / 2 + "px";
      }

      function isOverflown(element, maxHeight) {
        const isHeightOverflow = element.clientHeight > maxHeight;
        const isWidthOverflow =
          element.offsetWidth >
          element.parentElement.offsetWidth -
            (parseInt(getComputedStyle(element.parentElement).paddingLeft) +
              parseInt(getComputedStyle(element.parentElement).paddingRight));

        log &&
          console.log(
            "height",
            element.clientHeight,
            "maxHeight",
            maxHeight,
            "\n",
            "width",
            element.offsetWidth,
            "parent",
            element.parentElement.offsetWidth -
              (parseInt(getComputedStyle(element.parentElement).paddingLeft) +
                parseInt(getComputedStyle(element.parentElement).paddingRight)),
            "result",
            element.offsetWidth >
              element.parentElement.offsetWidth -
                (parseInt(getComputedStyle(element.parentElement).paddingLeft) +
                  parseInt(
                    getComputedStyle(element.parentElement).paddingRight
                  ))
          );

        return isHeightOverflow || isWidthOverflow;
      }

      function whileIsOverflown(
        overflownFontSize,
        refinementStepSize,
        callback
      ) {
        log && console.log("one step lower and stop...");

        overflownFontSize -= refinementStepSize;

        // Ensure the font size doesn't go below the minimum
        // overflownFontSize = Math.max(overflownFontSize, props.minFont);

        element.style.fontSize = overflownFontSize + "px";
        if (currencyElement)
          currencyElement.style.fontSize = overflownFontSize / 2 + "px";

        fontSize = overflownFontSize;

        requestAnimationFrame(function () {
          if (isOverflown(element, maxHeight)) {
            whileIsOverflown(overflownFontSize, refinementStepSize, callback);
          } else {
            log && console.log("not overflown");
            callback();
          }
        });
      }

      // Binary search for the optimal font size
      const binarySearchFontSize = (low, high, refinementCallback) => {
        if (this.tokenMismatch(token)) {
          cleanup();
          return reject();
        }

        log && console.log("---binary search---");
        if (low >= high) {
          refinementCallback();
          return;
        }

        fontSize = (low + high) / 2;
        element.style.fontSize = fontSize + "px";
        if (currencyElement)
          currencyElement.style.fontSize = fontSize / 2 + "px";

        log && console.table({ low, high, fontSize });

        requestAnimationFrame(function () {
          const elementHeight = element.offsetHeight;
          const elementWidth = element.offsetWidth;
          const exceedsIdealHeight = elementHeight > maxHeight;
          const belowIdealHeight = elementHeight < maxHeight - 10;
          const exceedsMaxWidth =
            elementWidth > element.parentElement.offsetWidth;

          if (
            exceedsIdealHeight ||
            exceedsMaxWidth ||
            isOverflown(element, maxHeight)
          ) {
            log && console.log("too high", { elementHeight, maxHeight });
            binarySearchFontSize(low, fontSize - 1, refinementCallback);
          } else if (belowIdealHeight) {
            log &&
              console.log("too low", {
                elementHeight,
                maxHeight: maxHeight - 10,
              });
            binarySearchFontSize(fontSize + 1, high, refinementCallback);
          } else {
            log && console.log("good", { fontSize, elementHeight, maxHeight });
            refinementCallback();
          }
        });
      };

      const refinementCallback = () => {
        if (this.tokenMismatch(token)) {
          cleanup();
          return reject();
        }

        // refinement of font size
        log && console.log("-------start refinement-------");
        let refinementStepSize = 1;
        let newFontSize = fontSize;
        let elementHeight = element.offsetHeight;
        let heightOfPreviousRefinedFontSize = element.offsetHeight;
        let heightTolerance = 10;
        // ToDo: remove this later...
        const preventInfiniteLoopThreshold = 100;
        let preventInfiniteLoopCounter = 0;

        const fontSizeRefinementLoop = (
          newFontSize,
          elementHeight,
          finalStepCallBack
        ) => {
          if (this.tokenMismatch(token)) {
            cleanup();
            return reject();
          }

          log && console.log("---doing refinement---");

          // if the font size is already good, stop
          if (
            elementHeight > maxHeight - heightTolerance &&
            elementHeight < maxHeight &&
            !isOverflown(element, maxHeight)
          ) {
            log && console.log("the font size is already good, stop");
            finalStepCallBack();
            return;
          } else {
            log &&
              console.log("no good enough...", {
                elementHeight,
                maxHeight,
                heightTolerance,
              });
          }

          // if it's too low but also overflown, stop and refine to lower font size
          if (
            elementHeight < maxHeight - heightTolerance &&
            isOverflown(element, maxHeight)
          ) {
            log &&
              console.log(
                "it's too low but also overflown, stop and refine to lower font size"
              );
            whileIsOverflown(
              newFontSize,
              refinementStepSize,
              finalStepCallBack
            );
            return;
          } else {
            log && console.log("not too low but also overflown...");
          }

          // ToDo: remove this later
          preventInfiniteLoopCounter++;
          // prevent infinite loop
          if (preventInfiniteLoopCounter > preventInfiniteLoopThreshold) {
            console.log("prevent infinite loop", element);
            fontSize = newFontSize;
            whileIsOverflown(
              newFontSize,
              refinementStepSize,
              finalStepCallBack
            );
            return;
          }

          // if none of the conditions are met, proceed to the refinement logic...
          let adjustment = 0;
          // if the element is too high or too low
          if (elementHeight > maxHeight) {
            adjustment = -refinementStepSize;
            log &&
              console.log("too high", {
                newFontSize,
                elementHeight,
                maxHeight,
                isOverflown: isOverflown(
                  element,
                  maxHeight,
                  element.parentElement
                ),
              });
          } else if (elementHeight < maxHeight - heightTolerance) {
            adjustment = refinementStepSize;
            log &&
              console.log("too low", {
                newFontSize,
                elementHeight,
                maxHeight,
                isOverflown: isOverflown(
                  element,
                  maxHeight,
                  element.parentElement
                ),
              });
          }

          newFontSize += adjustment;
          // uncomment this when the min and max values are actually important
          // newFontSize = Math.min(
          //   Math.max(newFontSize, props.minFont),
          //   props.maxFont
          // );
          element.style.fontSize = newFontSize + "px";
          if (currencyElement)
            currencyElement.style.fontSize = newFontSize / 2 + "px";

          requestAnimationFrame(function () {
            elementHeight = element.offsetHeight;

            // stop if not overflown or out of tolerance range
            if (
              elementHeight > maxHeight - heightTolerance &&
              elementHeight < maxHeight &&
              !isOverflown(element, maxHeight)
            ) {
              log &&
                console.log("after refinement: good, go to final call back");
              fontSize = newFontSize;
              finalStepCallBack();
              return;
            } else {
              log &&
                console.log(
                  "overflown or out of tolerance range",
                  elementHeight > maxHeight - heightTolerance,
                  elementHeight < maxHeight,
                  !isOverflown(element, maxHeight)
                );
            }

            // stop if stuck between two font sizes
            if (
              (elementHeight > maxHeight &&
                heightOfPreviousRefinedFontSize <
                  maxHeight - heightTolerance) ||
              (elementHeight < maxHeight - heightTolerance &&
                heightOfPreviousRefinedFontSize > maxHeight)
            ) {
              log &&
                console.log("stuck between two font sizes", {
                  elementHeight,
                  maxHeight,
                  heightOfPreviousRefinedFontSize,
                  heightTolerance,
                  newFontSize,
                });

              newFontSize = Math.min(newFontSize, newFontSize - adjustment);
              element.style.fontSize = newFontSize + "px";
              if (currencyElement)
                currencyElement.style.fontSize = newFontSize / 2 + "px";

              setTimeout(function () {
                elementHeight = element.offsetHeight;

                if (isOverflown(element, maxHeight)) {
                  whileIsOverflown(
                    newFontSize,
                    refinementStepSize,
                    finalStepCallBack
                  );
                } else {
                  fontSize = newFontSize;
                  finalStepCallBack();
                }
              });
              return;
            } else {
              log &&
                console.log("not stuck between two font sizes, continue...");
            }

            heightOfPreviousRefinedFontSize = elementHeight;
            log && console.log("refinement", { newFontSize, elementHeight });
            fontSizeRefinementLoop(
              newFontSize,
              elementHeight,
              finalStepCallBack
            );
          });
        };

        const finalStepCallBack = () => {
          if (this.tokenMismatch(token)) {
            cleanup();
            return reject();
          }

          log &&
            console.log(
              "final fontSize",
              fontSize,
              "parent height: ",
              window.getComputedStyle(element.parentElement).height
            );

          // Set the final font size
          element.style.fontSize = fontSize + "px";
          if (currencyElement)
            currencyElement.style.fontSize = newFontSize / 2 + "px";
          element.style.opacity = "1";

          // update the cache
          let fitTextCache = vm.$store.state.fitTextCache;
          // Ensure the productId and themeId levels exist, or create them with default objects
          fitTextCache[productId] = fitTextCache[productId] || {};
          fitTextCache[productId][themeId] =
            fitTextCache[productId][themeId] || {};

          // Set the fontSize for the className under the appropriate productId and themeId
          fitTextCache[productId][themeId][className] = fontSize;

          vm.$store.dispatch("setFitTextCache", fitTextCache);

          resolve();
        };

        fontSizeRefinementLoop(newFontSize, elementHeight, finalStepCallBack);
      };

      binarySearchFontSize(props.minFont, props.maxFont, refinementCallback);
    });
  },
};
