<template>
  <div>
    <v-snackbar
      v-model="show"
      :color="color"
      :timeout="timeout"
      bottom
      :class="[text === 'Uploading' ? 'upload' : '']"
    >
      <template v-if="text === 'Uploading'">
        <v-progress-circular
          v-model="uploadProgress"
          :rotate="360"
          :size="30"
          :width="3"
          color="white"
        >
          <template v-slot:default>
            <span class="font-6 font-600"
              >{{ Math.ceil(uploadProgress) }}%</span
            >
          </template>
        </v-progress-circular>
        <span class="ml-3">{{ text }}</span>
      </template>

      <template v-else>
        <span
          v-if="text.toLowerCase().includes('successfully')"
          class="mdi mdi-check-bold"
        ></span>
        {{ text }}
      </template>

      <!-- <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="show = false"> Close </v-btn>
      </template> -->
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      color: "",
      text: "",
      timeout: -1,
    };
  },
  computed: {
    uploadProgress() {
      return this.$store.getters["offersModule/uploadProgress"];
    },
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "SHOW_MESSAGE") {
        this.text = state.text;
        this.color = "#0195A9";
        this.timeout = state.timeout;
        this.show = true;
      }
    });
  },
};
</script>

<style lang="scss">
.upload {
  .v-snack__content {
    text-align: start;
    padding-block: 8px;

    .font-6 {
      font-size: 6px;
    }
  }
}
</style>
