import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/style/main.scss";
import "./assets/style/_variables.scss";
import BaseInput from "./components/base/Input";
import BaseDate from "./components/base/Date";
import BaseSelect from "./components/base/Select";
import DeleteItem from "./components/dialogs/DeleteItem";
import DeleteDialog from "./components/dialogs/DeleteDialog";
import axios from "./plugins/axios";
import moment from "moment-timezone";
import "material-design-icons-iconfont/dist/material-design-icons.css";
// slick carousel
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);
import VueFullscreen from "vue-fullscreen";
import draggable from "vuedraggable";
import { StripePlugin } from "@vue-stripe/vue-stripe";
const options = {
  pk: "pk_test_51MmS84HjR0GowZkQC5rbb5dO43e7dVWvGLJ1xEmUwXfTv6HA61le5OKdsb61dgMUcFmgOlu2JNpv2FjoR68qU07j00ESQoytEm",
  stripeAccount: "acct_1MmS84HjR0GowZkQ",
  locale: "en",
};
Vue.use(StripePlugin, options);
Vue.component("base-input", BaseInput);
Vue.component("base-date", BaseDate);
Vue.component("base-select", BaseSelect);
Vue.component("slick-carousel", VueSlickCarousel);

Vue.use(VueFullscreen);
Vue.component("draggable", draggable);
import "@/mixins/index.js";

import "swiper/swiper-element-bundle.min.js";
import "swiper/swiper.min.css";
Vue.config.productionTip = false;
Vue.component("delete-dialog", DeleteItem);
Vue.component("custom-delete", DeleteDialog);
Vue.use(axios);
Vue.prototype.$moment = moment;
// import * as Sentry from "@sentry/vue";

// Sentry.init({
//   Vue,
//   dsn: "https://31e814378522be97f3f5165588c0a1ae@o4506276126851072.ingest.sentry.io/4506276134780928",
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/api.dev.bisni.net\/api/],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
