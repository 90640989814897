// modules/myModule.js
import axios from "../../plugins/axios";

const offersModule = {
  namespaced: true, // Enable namespace for the module

  state: {
    allData: [],
    allTrash: [],
    banners: [],
    validCount: null,
    uploadProgress: 0,
  },
  getters: {
    allData: (state) => state.allData,
    allTrash: (state) => state.allTrash,
    validCount: (state) => state.validCount,
    banners: (state) => state.banners,
    uploadProgress: (state) => state.uploadProgress,
  },
  mutations: {
    SET_ALL_DATA(state, payload) {
      state.allData = payload;
    },
    SET_ALL_TRASH(state, payload) {
      state.allTrash = payload;
    },
    SET_ALL_BANNER(state, payload) {
      state.banners = payload;
    },
    SET_VALID_COUNT(state, payload) {
      state.validCount = payload;
    },

    SET_NEW_GROUP(state, payload) {
      state.allData = [payload, ...state.allData];
      state.validCount = state.validCount + 1;
    },
    SET_NEW_PRODUCT(state, payload) {
      const find = state.allData.find((x) => x._id == payload.group);
      find.products = [
        ...find.products,
        {
          _id: "0000000000000000000000000000",
          item: payload,
          sort: 0,
        },
      ];

      state.allData = [...state.allData];
    },
    SET_NEW_BANNER(state, payload) {
      //console.log("payload :>> ", payload);
      state.banners = [...state.banners, payload];
    },
    SET_UPDATE_PRODUCT(state, payload) {
      const find = state.allData.find((x) => x._id == payload.group);
      let findProduct = find.products.find((x) => x.item._id == payload._id);
      findProduct.item = payload;
      const newData = state.allData;
      state.allData = newData;
    },
    SET_UPLOAD_PROGRESS(state, payload) {
      state.uploadProgress = payload;
    },
  },
  actions: {
    async getAllData({ rootState, commit, dispatch }, payload) {
      const params = {
        filters: payload,
        acceptempty: true,
        isdeleted: false,
        ispdeleted: false,
      };
      let { data } = await axios.get(process.env.VUE_APP_GROUPS, {
        params,
      });
      if (data.success) {
        // sanitize inactive themes first
        new Promise((resolve) => {
          if (Array.isArray(data.Categories)) {
            data.Categories.forEach((category, i, arr) => {
              if (rootState.inactiveThemes.includes(category.theme.numericId)) {
                // send an API request to edit it to first active theme
                dispatch("changeGroupTheme", {
                  data: { themeId: rootState.fetchedThemes[0]._id },
                  id: category._id,
                })
                  .then((res) => {
                    if (i === arr.length - 1) {
                      resolve();
                    }
                  })
                  .catch((err) => {
                    dispatch("showSnack", {
                      text: err.message || err.error || "Something went wrong",
                      color: "error",
                    });
                  });
              } else {
                if (i === arr.length - 1) {
                  resolve();
                }
              }
            });
          } else {
            resolve();
          }
        }).then(() => {
          commit("SET_ALL_DATA", data.Categories);
          commit("SET_VALID_COUNT", data.validCount);
        });
      }
    },
    async getAllTrash({ commit }, payload) {
      const params = {
        acceptempty: true,
        isdeleted: true,
      };
      let { data } = await axios.get(process.env.VUE_APP_GROUPS, {
        params,
      });
      if (data.success) {
        commit("SET_ALL_TRASH", data.Categories);
        //commit("SET_VALID_COUNT", data.validCount);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getAllBanners({ commit }, payload) {
      let { data } = await axios.get(process.env.VUE_APP_BANNERS);
      commit("SET_ALL_BANNER", data.data);
    },
    async saveGroup({ commit, dispatch, rootGetters }, payload) {
      return axios
        .post(process.env.VUE_APP_GROUPS, payload)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log({ err });
          return err;
        });
    },
    // eslint-disable-next-line no-unused-vars
    async updateGroup({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${process.env.VUE_APP_GROUPS}/${payload._id}`, payload)
          .then((res) => {
            let index = state.allData.findIndex(
              (obj) => obj._id === payload._id
            );
            if (index !== -1) {
              state.allData[index] = res.data.group;
            }
            commit("SET_ALL_DATA", state.allData);

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async saveProduct({ commit, dispatch }, { groupId, formData }) {
      return new Promise((resolve, reject) => {
        const addProductToGroupUrl = `${process.env.VUE_APP_GROUPS}/${groupId}/products`;

        let showType = formData.get("show");

        const config = {
          onUploadProgress: (progressEvent) => {
            dispatch(
              "setUploadProgress",
              (progressEvent.loaded / progressEvent.total) * 100
            );
          },
        };

        axios
          .post(
            addProductToGroupUrl,
            formData,
            showType === "video" ? config : {}
          )
          .then(({ data }) => {
            dispatch("getAllData");
            dispatch(
              "setProductNumber",
              {
                productsAllowed: data.planDetails.productsAllowed,
                productsUsed: data.planDetails.productsUsed,
              },
              { root: true }
            );

            dispatch("setUploadProgress", 0);
            // dispatch("showSnack", {
            //   text: `Created Successfully`,
            //   color: "success",
            // });
            resolve(data);
          })
          .catch((err) => {
            if (err.message === "limit exceeded") {
              dispatch("setProTipDialog", {
                show: true,
                title: "You've Reached Your Limit!",
                description: "Don't Let Limits Hold You Back.",
              });
            } else {
              // dispatch("showSnack", {
              //   text: err.message || err.error || "Something went wrong!",
              //   color: "error",
              // });
            }

            dispatch("setUploadProgress", 0);
            reject(err);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    async updateProduct({ commit, dispatch }, { productId, formData }) {
      return new Promise((resolve, reject) => {
        let showType = formData.get("show");
        const config = {
          onUploadProgress: (progressEvent) => {
            dispatch(
              "setUploadProgress",
              (progressEvent.loaded / progressEvent.total) * 100
            );
          },
        };

        axios
          .patch(
            `${process.env.VUE_APP_PRODUCTS}/${productId}`,
            formData,
            showType === "video" ? config : {}
          )
          .then((res) => {
            commit("SET_UPDATE_PRODUCT", res.data.product);

            dispatch("setUploadProgress", 0);
            // dispatch("showSnack", {
            //   text: `Created Successfully`,
            //   color: "success",
            // });
            resolve(res);
          })
          .catch((err) => {
            dispatch("setUploadProgress", 0);
            // dispatch("showSnack", {
            //   text: err.message || err.error || "Something went wrong",
            //   color: "error",
            // });
            reject(err);
          });
      });
      // const updateProductUrl = `${process.env.VUE_APP_PRODUCTS}/${productId}`;
      // const { data } = await axios.patch(updateProductUrl, formData);

      // commit("SET_UPDATE_PRODUCT", data.product);
      // return data;
    },
    setUploadProgress({ commit }, payload) {
      commit("SET_UPLOAD_PROGRESS", payload);
    },
    // eslint-disable-next-line no-unused-vars
    async changeGroupTheme({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${process.env.VUE_APP_GROUPS}/${payload.id}`, payload.data)
          .then((res) => {
            let index = state.allData.findIndex((obj) => obj._id == payload.id);
            if (index !== -1) {
              state.allData[index] = res.data.group;
            }
            //console.log("state.allData :>> ", state.allData);
            commit("SET_ALL_DATA", state.allData);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async deleteGroup({ state, commit, dispatch }, payload) {
      const filter = await state.allData.filter(
        (x) => x._id != payload.group._id
      );
      //console.log(filter, 111);
      const find = await state.allData.find((x) => x._id == payload.group._id);
      //console.log(find, 44);

      commit("SET_ALL_DATA", filter);
      commit("SET_VALID_COUNT", filter.length);

      if (!find) {
        const filter = await state.allTrash.filter(
          (x) => x._id != payload.group._id
        );
        commit("SET_ALL_TRASH", filter);
      }
      dispatch(
        "setProductNumber",
        {
          productsAllowed: payload.planDetails?.productsAllowed,
          productsUsed: payload.planDetails?.productsUsed,
        },
        { root: true }
      );
      return filter;
    },
    // eslint-disable-next-line no-unused-vars
    sortProducts({ state, dispatch }, payload) {
      axios
        .post(`${process.env.VUE_APP_GROUPS}/${payload.groupId}/sort`, {
          productsIds: payload.products,
        })
        .then((res) => {})
        .catch((err) => {
          dispatch(
            "showSnack",
            {
              text: err.message || err.error || "Something went wrong",
              color: "error",
            },
            { root: true }
          );
        });
    },

    async deleteProduct({ state, commit, dispatch }, payload) {
      const findGroup = await state.allData.find(
        (x) => x._id == payload.product.group
      );
      const filter = findGroup.products.filter(
        (x) => x.item._id != payload.product._id
      );
      findGroup.products = filter;
      commit("SET_ALL_DATA", state.allData);
      dispatch("getAllTrash", "deleted");
      dispatch(
        "setProductNumber",
        {
          productsAllowed: payload.planDetails?.productsAllowed,
          productsUsed: payload.planDetails?.productsUsed,
        },
        { root: true }
      );
    },

    // eslint-disable-next-line no-unused-vars
    async restoreProduct({ state, commit, dispatch }, payload) {
      let formData = { status: "restored" };
      // formData.append("deleted", !payload.deleted);
      const { data } = await axios.put(
        `${process.env.VUE_APP_PRODUCTS}/${payload._id}/status`,
        formData
      );
      const find = await state.allTrash.find((x) => x._id == payload.group);
      const filter = await find.products.filter(
        (x) => x.item._id != payload._id
      );
      find.products = filter;
      await dispatch("getAllData");
      await dispatch("getAllTrash", "deleted");

      dispatch(
        "setProductNumber",
        {
          productsAllowed: data.planDetails?.productsAllowed,
          productsUsed: data.planDetails?.productsUsed,
        },
        { root: true }
      );
    },
    // eslint-disable-next-line no-unused-vars
    async restoreGroup({ state, commit, dispatch }, payload) {
      const { data } = await axios.put(
        `${process.env.VUE_APP_GROUPS}/${payload._id}/status`,
        {
          status: "restored",
        }
      );
      const filter = await state.allTrash.filter((x) => x._id != payload._id);
      //console.log(data,555,filter)
      state.allTrash = filter;
      commit("SET_ALL_TRASH", filter);
      commit("SET_VALID_COUNT", state.allData.length + 1);
      if (data) {
        dispatch(
          "setProductNumber",
          {
            productsAllowed: data.planDetails?.productsAllowed,
            productsUsed: data.planDetails?.productsUsed,
          },
          { root: true }
        );
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createBanner({ state, commit, dispatch }, payload) {
      // commit("SET_NEW_BANNER", payload);
      await axios.post(process.env.VUE_APP_BANNERS, {
        text: payload.text,
      });

      await dispatch("getAllBanners");
    },

    async deleteBanner({ state, commit }, payload) {
      await axios.delete(`${process.env.VUE_APP_BANNERS}/${payload}`);
      const filter = state.banners.filter((x) => x._id != payload);
      commit("SET_ALL_BANNER", filter);
    },
  },
};

export default offersModule;
