<template>
  <div
    class="base-input input-style relative"
    :class="type == 'number' ? 'base-phone' : ''"
  >
    <v-text-field
      class="w-100"
      :type="type"
      :outlined="outlined"
      :placeholder="placeholder"
      :name="name"
      dense
      height="44px"
      max-height="44px"
      :rules="rules"
      :hide-details="hideDetails"
      :value="value"
      @input="$emit('input', $event)"
      :rounded="rounded"
      :append-icon="type == 'email' && hasValue ? 'mdi-check' : ''"
      :prepend-icon="icon"
      full-width
      :readonly="readonly"
      color="black"
      :autocomplete="autocomplete"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      // validator: () => true,
    },
    type: {
      type: String,
      default: "text",
    },
    title: {
      type: String,
      default: "text",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasValue: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.input-style {
  .v-input__slot {
    border-radius: 10px;
    fieldset {
      height: 49px !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      box-shadow: 0px 2px 10px rgb(168 168 168 / 5%);
    }
    .v-text-field__slot {
      height: 44px !important;
    }
  }
  .v-input__append-inner {
    margin-top: 0px !important;
  }
  .error--text {
    .v-input__slot {
      fieldset {
        height: 49px !important;
        border: 1px solid #e44358 !important;
      }
    }
  }
}
.base-phone input::-webkit-outer-spin-button,
.base-phone input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
.base-phone input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
