<template>
  <div class="confirm-subscription">
    <div>
      <h1 class="font-20 black--text d-flex font-700">
        <img
          class="pro-flag mr-2"
          width="42px"
          height="27px"
          :src="require('@/assets/images/icons/pro-flag.svg')"
        />
        {{ title }}
      </h1>
      <p class="font-16 font-400 icon-black--text mb-6">
        {{ description }}
      </p>
    </div>

    <div class="d-flex align-center justify-end mt-10">
      <v-btn
        class="radius-15 flex-grow-1"
        height="45px"
        color="primary"
        elevation="0"
        @click="redirectToPlansPage()"
      >
        <span class="white--text font-18 font-600">Go pro now!</span>
      </v-btn>

      <v-btn
        class="ml-4 no-box-shadow"
        width="80px"
        height="45px"
        color="transparent"
        @click="$emit('close')"
      >
        <span class="font-18 font-400">Cancel</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    redirectToPlansPage() {
      this.$emit("close");
      this.$router.push("/my-plan");
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-notice {
  border-radius: 19px;
  background: #fdd039;
  padding: 15px;
}
</style>
