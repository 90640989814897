import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "vue-transitions-css";
import "@mdi/font/css/materialdesignicons.css";
Vue.use(Vuetify);
const opts = {
  rtl: localStorage.getItem("language") == "ar",
  theme: {
    themes: {
      light: {
        primary: "#0195A9",
        lightred: "#E57171",
        lightgreen: "#5AC587",
        greybg: "#F5F5F5",
        darkgrey: "#D9D9D9",
        darkblue: "#252F67",
        greenbg: "#D2E8E3",
        greytext: "#737373",
        "bg-grey": "#F4F4F4",
        blue: "#2F4081",
        "icon-grey": "#AFAFAF",
        lightprimary: "#D2E8E3",
        "darkbg-grey": "#E1E1E1",
        warning: "#FFA500",
        danger: "#FF0D0D",
        error: "#FF0D0D",
      },
    },
  },
};

export default new Vuetify(opts);
