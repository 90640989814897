<template>
  <div class="delete-dialog">
    <div class="px-10 text-center">
      <span class="mt-10 d-block font-20 black--text font-500">
        Are Your Sure?</span
      >
      <!-- <img
        class="my-14"
        :src="require('@/assets/images/profile/delete.png')"
        alt=""
      /> -->
      <div class="mb-10">
        <v-btn
          class="c-btn mx-1"
          color="grey"
          height="40"
          @click="$emit('close')"
          ><span class="white--text font-300 font-20 d-inline-block px-7"
            >No</span
          ></v-btn
        >
        <v-btn
          class="c-btn mx-1"
          color="error"
          height="40"
          :loading="loading"
          :disabled="loading"
          @click="deleteType === 'archive' ? archiveHandler() : deleteHandler()"
          ><span class="white--text font-300 font-20 d-inline-block px-7"
            >Yes</span
          ></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    urlApi: {
      type: String,
      default: "",
    },
    itemID: {
      type: [String, Array],
      default: "",
    },
    multiDelete: {
      type: Boolean,
      default: false,
    },
    method: {
      type: String,
      default: "delete",
    },
    deleteType: {
      type: String,
      default: "delete",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async archiveHandler() {
      this.loading = true;
      this.$axios.put(`${this.urlApi}/${this.itemID}/status`, {
        status: "archived",
      })
        .then(({ data }) => {
          this.loading = false;
          this.$store.dispatch("showSnack", {
            text: "Archived Successfully",
            color: "success",
          });
          this.deleteDialog = false;
          this.$emit("close");
          this.$emit("getData", data);
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch("showSnack", {
            text: err.message || err.error || "Something went wrong",
            color: "error",
          });
        });
    },
    async deleteHandler() {
      this.loading = true;

      try {
        if (this.multiDelete) {
          this.$emit("deleteHandler");
          this.loading = false;
          return;
        } else {
          //console.log(this.urlApi, this.itemID);
          let { data } = await this.$axios[this.method](
            `${this.urlApi}/${this.itemID}`
          );
          // //console.log(data);
          if (data.success) {
            this.loading = false;
            this.$store.dispatch("showSnack", {
              text: "Deleted Successfully",
              color: "success",
            });
            this.deleteDialog = false;
            this.$emit("close");
            this.$emit("getData", data);
          }
        }
      } catch (error) {
        //console.log(error);
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: error.message || "Something went wrong",
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
